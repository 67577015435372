import {enableProdMode} from '@angular/core'


import {environment} from './environments/environment'
import {AppComponent} from './app/app.component'
import {bootstrapApplication} from '@angular/platform-browser'
import {registerLocaleData} from '@angular/common'

import localeFrExtra from '@angular/common/locales/extra/fr'
import localeFr from '@angular/common/locales/fr'

import config from './app/app-config'

if (environment.production) {
  enableProdMode()
}

registerLocaleData(localeFr, 'fr', localeFrExtra)


bootstrapApplication(AppComponent, config)
  .catch(err => console.error(err))
