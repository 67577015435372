import {Component, inject, OnInit} from '@angular/core'
import {ClosedService} from '../../../services/closed.service'
import {Router} from '@angular/router'
import {timer} from 'rxjs'

@Component({
  selector: 'spb-closed',
  imports: [],
  templateUrl: './closed.component.html',
  styleUrl: './closed.component.scss'
})
export class ClosedComponent implements OnInit {

  public opens: string = ''

  private service = inject(ClosedService)
  private router = inject(Router)


  public ngOnInit(): void {
    this.service.closed$.subscribe({
      next: () => {
        const date = new Date(this.service.end)
        this.opens = `klockan ${date.toLocaleTimeString('sv-SE').substring(0, 5)}`
        const today = new Date().toLocaleDateString('sv-SE')
        if (today !== date.toLocaleDateString('sv-SE')) {
          this.opens = today + ' ' + this.opens
        }
      }
    })
    /**
     * If we are here we navigate to root after
     * closing hours.
     */
    timer(new Date(this.service.end)).subscribe({
      next: () => this.router.navigate(['/'])

    })
  }
}
