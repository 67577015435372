import {Component, Input} from '@angular/core'

@Component({
  selector: 'spb-progress',
  templateUrl: './progress.component.html',
  styleUrls: ['./progress.component.scss'],
  standalone: true
})
export class ProgressComponent {
  @Input() text = 'Vänta'
}
