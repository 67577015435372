import {Component, inject} from '@angular/core'
import {UserService} from '../../services/user.service'
import {RouterLink} from '@angular/router'
import {MatIcon} from '@angular/material/icon'
import {MatMenu, MatMenuItem, MatMenuTrigger} from '@angular/material/menu'
import {MatButton} from '@angular/material/button'
import {LogoComponent} from '@sparbanken-syd/sparbanken-syd-theme'

@Component({
  selector: 'spb-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  imports: [MatButton, MatMenuTrigger, MatIcon, MatMenu, MatMenuItem, RouterLink, LogoComponent]
})
export class HeaderComponent {
  public userService = inject(UserService)
}
