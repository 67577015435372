@if (data.title) {
  <div class="title">{{ data.title }}</div>
}
<mat-dialog-content>
  @if (data.text) {
    <div matDialogContent>{{ data.text }}</div>
  }
  @if (data.html) {
    <div [innerHtml]="htmlText" matDialogContent></div>
  }
  @if (data.progress) {
    <mat-progress-bar [style.min-width.px]="250" mode="indeterminate"></mat-progress-bar>
    <!-- spb-progress-ng></spb-progress-ng -->
  }
</mat-dialog-content>
@if (data.closable) {
  <mat-dialog-actions>
    <button matDialogClose="" mat-raised-button color="primary">Stäng</button>
  </mat-dialog-actions>
}