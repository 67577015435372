import {Component, inject, OnInit} from '@angular/core'
import {ActivatedRoute, Router, RouterLink} from '@angular/router'
import {DocumentService} from '../../services/document.service'
import {BorgoAuthSate, CodeInput, CodeResponse} from 'sparbanken-syd-borgo'
import {catchError, NEVER} from 'rxjs'
import {HttpErrorResponse, HttpParams} from '@angular/common/http'
import {DOCUMENTS_ROUTE_PATH, KYC_ROUTE_PATH, LETTER_ROUTE_PATH, LOAN_ROUTE_PATH} from '../../application/data-types'
import {UserService} from '../../services/user.service'
import {MatExpansionPanel, MatExpansionPanelContent, MatExpansionPanelHeader} from '@angular/material/expansion'
import {ProgressComponent} from '../progress/progress.component'


@Component({
  selector: 'spb-code',
  templateUrl: './code.component.html',
  styleUrls: ['./code.component.scss'],
  imports: [ProgressComponent, RouterLink, MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelContent]
})
export class CodeComponent implements OnInit {

  public error = false
  public errorMessage: string | undefined

  /**
   * This was introduced when I thought there was
   * a difference in paths. I leave it here in case
   */
  private paths: Record<BorgoAuthSate, string[]> = {
    AUTH: [DOCUMENTS_ROUTE_PATH, 'userId'],
    AUTOGIRO: [DOCUMENTS_ROUTE_PATH, 'userId'],
    SIGN: [DOCUMENTS_ROUTE_PATH, 'userId'],
    LETTER: [LETTER_ROUTE_PATH, 'klar'],
    LOAN: [LOAN_ROUTE_PATH, 'start'], // Go back to start so that we can load data.
    KYC: [KYC_ROUTE_PATH, 'input'],
    KYC_UPDATE: [KYC_ROUTE_PATH, 'dokument', 'userId']
  }

  private route = inject(ActivatedRoute)
  private router = inject(Router)
  private dataService = inject(DocumentService)
  private userService = inject(UserService)

  constructor() {
  }

  ngOnInit(): void {

    const params = new HttpParams({fromString: this.route.snapshot.fragment as string})
    const request: CodeInput = {
      code: params.get('code') as string,
      state: params.get('state') as string
    }

    if (!params.has('code')) {
      this.error = true
      this.errorMessage = params.get('error_description') as string
    } else {
      this.userService.code(request).pipe(
        catchError((err: HttpErrorResponse) => {
          this.error = true
          this.errorMessage = err.message
          return NEVER
        })
      ).subscribe({
        next: (res: CodeResponse) => {
          const path = this.paths[res.state]
            .map((p: string) => p.replace('userId', res.userId)
              .replace('null', ''))
          // If autogiro is registered, then we hide the autogiro.
          //
          if (res.autogiro) {
            this.dataService.addToken(res.autogiro)
          }
          this.router.navigate(path.filter((p: string) => p), {queryParams: {context: res.context}}).then()
        }
      })
    }
  }
}
