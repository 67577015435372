import {Component, inject, Inject} from '@angular/core'
import {MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent} from '@angular/material/dialog'
import {DomSanitizer, SafeHtml} from '@angular/platform-browser'
import {MatButton} from '@angular/material/button'
import {MatProgressBar} from '@angular/material/progress-bar'

export interface IWaitComponentData {
  /**
   * The title of the dialog
   */
  title: string

  /**
   * Text if any, either you send this or html
   */
  text?: string

  /**
   * Plain HTML if you want sanitized for you. Be
   * careful to let user data in here.
   */
  html?: string

  /**
   * If we should show the close button or not.
   * Not sure if this is part of the other dialog
   * data?
   */
  closable?: boolean

  /**
   * Show a neat progress bar, defaults to
   * not.
   */
  progress?: boolean
}

@Component({
  selector: 'spb-wait',
  templateUrl: './wait-component.component.html',
  styleUrl: './wait-component.component.scss',
  imports: [
    MatDialogContent,
    MatDialogActions,
    MatButton,
    MatDialogClose,
    MatProgressBar
  ]
})
export class WaitComponent {

  public htmlText: SafeHtml = ''

  private sanitizer: DomSanitizer = inject(DomSanitizer)

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IWaitComponentData) {

    if (data.html) {
      this.htmlText = this.sanitizer.bypassSecurityTrustHtml(data.html)
    }
  }
}
