export const environment = {
  production: true,
  apiUrl: 'https://api-bo-stage.lana.sparbankensyd.se/api',
  authServiceUrl: 'https://spb-common-api.internal.sparbankensyd.se/service',
  rtUrl: 'https://api-rt-stage.internal.sparbankensyd.se/api',
  nextGeneration: false,
  domain: 'bo.internal.sparbankensyd.se',
  testMode: false,
  developerMode: false
}
